import React from 'react';

function Frame4() {
  return (
    <div className="bg-[#FFFFFF] flex flex-col  p-4 md:p-12 w-full xl:w-[full] box-sizing-border">
      <div className="mb-6 inline-block self-start break-words font-['Poppins'] font-semibold text-[20px] tracking-[0.5px] text-[#1A9A6F]">
        Who We Serve
      </div>

      <div className="flex flex-col  w-full ">
        <div className="border_bruh border-green-400 rounded-[10px] bg-[#FFFFFF] flex flex-row justify-between p-4 w-full sm:w-[500px] md:w-[700px] mb-6 box-sizing-border  self-end lg:mx-20">
          <span className="self-center mr-4 break-words font-['Poppins'] font-bold text-[21px] tracking-[0.5px] leading-[1.38] text-[rgba(0,0,0,0.8)]">
            Healthcare Software <br />
            Companies
          </span>
          <div className="break-words font-['Poppins'] font-normal  text-[14px] tracking-[0.5px] leading-[1.38] text-[#000000] ">
            It offers a variety of solutions, including <br />
            revenue cycle management, analytics, <br />
            and patient flow management.
          </div>
        </div>

        <div className="border_bruh rounded-[10px] bg-[#FFFFFF] flex flex-row justify-between p-4 w-full sm:w-[500px] md:w-[700px] mb-6 box-sizing-border self-start lg:mx-20">
          <span className=" self-center mr-4 break-words font-['Poppins'] font-bold  text-[21px] tracking-[0.5px] leading-[1.38] text-[rgba(0,0,0,0.8)]">
            Healthcare <br />
            Technology Investors
          </span>
          <div className="break-words font-['Poppins'] font-normal text-[14px] tracking-[0.5px] leading-[1.38] text-[#000000]">
            Known for their early-stage investments, <br />
            SOSV has a significant presence in the <br />
            digital therapeutics sector.
          </div>
        </div>

        <div className="border_bruh rounded-[10px] bg-[#FFFFFF] flex flex-row justify-between p-4 w-full sm:w-[500px] md:w-[700px] mb-6 box-sizing-border self-end  lg:mx-20  ">
          <div className= " self-center break-words font-['Poppins'] font-bold  text-[21px] tracking-[0.5px] leading-[1.38] text-[rgba(0,0,0,0.8)]">
            Healthcare Providers
          </div>
          <span className="break-words font-['Poppins'] font-normal text-[14px] tracking-[0.5px] leading-[1.38] text-[#000000]">
            Known for its integrated healthcare <br />
            delivery model combining health <br />
            services with a robust health insurance.
          </span>
        </div>

        <div className="border_bruh rounded-[10px] bg-[#FFFFFF] flex flex-row justify-between p-4 w-full sm:w-[500px] md:w-[700px] mb-6 box-sizing-border self-start lg:mx-20">
          <div className=" self-center break-words font-['Poppins'] font-bold  text-[21px] tracking-[0.5px] leading-[1.38] text-[rgba(0,0,0,0.8)]">
            Health Plans
          </div>
          <span className="break-words font-['Poppins'] font-normal text-[14px] tracking-[0.5px] leading-[1.38] text-[#000000]">
            It's known for its integrated care model, <br />
            combining health services and insurance, <br />
            which results in high-quality and <br />
            coordinated care.
          </span>
        </div>

        <div className="border_bruh rounded-[10px] bg-[#FFFFFF] flex flex-row justify-between p-4 w-full sm:w-[500px] md:w-[700px] mb-6 box-sizing-border  self-end lg:mx-20 ">
          <span className=" self-center mr-4 break-words font-['Poppins'] font-bold  text-[21px] tracking-[0.5px] text-[rgba(0,0,0,0.8)]">
            Pharmaceutical and <br />
            Life Science
          </span>
          <div className="break-words font-['Poppins'] font-normal text-[14px] tracking-[0.5px] leading-[1.38] text-[#000000]">
            Known for its breakthrough COVID-19 <br />
            vaccine, Pfizer remains a top <br />
            pharmaceutical firm
          </div>
        </div>
      </div>
    </div>
  );
}

export default Frame4;

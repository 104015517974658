import React, { useState } from 'react';
import Select from 'react-select';
import emailjs from 'emailjs-com';
import computer from './assests/hand1.jpg';

const countryOptions = [
  { value: 'IA', label: 'India' },
  { value: 'CA', label: 'Canada' },
  { value: 'GB', label: 'United Kingdom' },
  { value: 'US', label: 'United States' },
  // Add more countries as needed
];

function Frame7() {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    companyName: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      ...formData,
      country: selectedCountry ? selectedCountry.label : ''
    };

    // 'service_t2q4xf8', 'template_zjefl1q', templateParams,"PtpjS-cfP143RjBnl"     ithu antha emailjs.send kulla amil anupurathu
    emailjs.send()
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert('Message sent successfully!');
      })
      .catch((err) => {
        console.log('FAILED...', err);
        alert('Failed to send message. Please try again.');
      });
  };
  const [phoneData, setPhoneData] = useState({
    phoneNumber: ''
  });

  const handlePhoneChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phoneNumber') {
      const cleanedValue = value.replace(/\D/g, ''); // Remove non-digit characters
      if (cleanedValue.length <= 10) {
        setPhoneData({
          ...phoneData,
          [name]: cleanedValue
        });
      }
    } else {
      setPhoneData({
        ...phoneData,
        [name]: value
      });
    }
  };

  return (
    <div className="w-full mx-auto m-5">
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col lg:flex-row lg:justify-between p-5  lg:p-10 w-full">
          <div className="rounded-3xl lg:mb-0 lg:mr-8  sm:mr-0 mb-8 lg:mb-0">
            <div className="text-start font-[Poppins]  mb-6 font-semibold text-medium tracking-wide leading-loose text-green-600">
              Get Started Quickly and get results
            </div>
            <div
              className="relative bg-cover bg-center bg-no-repeat"
              style={{ backgroundImage: `url(${computer})`, height: '200px' }}
            />
            <div>
              <h2 className='text-start font-bold mt-10 text-[20px]'>What You Get When Asking Your Question</h2>
              <div className='text-start mt-5 text-[15px] font-[Poppins]'>
                <li className='mb-1'>Less than 12-hours response to your question.</li>
                <li className='mb-1'>Plan of action summarized in an email follow up.</li>
                <li className='mb-1'>Clear and concise answers to complex questions.</li>
                <li className='mb-1'>Access to additional resources and references.</li>
              </div>
            </div>
          </div>

          <div className="border rounded-lg border-blue-300 text-start bg-gray-100 p-4  flex flex-col w-full lg:w-1/2">
            <div className="mb-4 font-semibold text-xl tracking-wide leading-loose text-green-600 font-[Poppins]">
              Contact us
            </div>
            <div className="mb-6 font-normal text-sm tracking-wide leading-loose text-black font-[Poppins]">
              Enter your contact details below and we will reach out to you by <br /> next business day
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex flex-col">
                <label htmlFor="firstName" className="font-medium text-sm text-black">First name</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  className="border rounded-md p-2 mt-1 focus:outline-none w-full"
                  value={formData.firstName}
                  onChange={handleChange}
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="lastName" className="font-medium text-sm text-black">Last name</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  className="border rounded-md p-2 mt-1 focus:outline-none w-full"
                  value={formData.lastName}
                  onChange={handleChange}
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="email" className="font-medium text-sm text-black">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="border rounded-md p-2 mt-1 focus:outline-none w-full"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="flex flex-col overflow-hidden">
      <label htmlFor="phoneNumber" className="font-medium text-sm text-black">
        Phone number
      </label>
      <input
        type="text" // Use text input to allow validation
        id="phoneNumber"
        name="phoneNumber"
        className="border rounded-md p-2 mt-1 focus:outline-none w-full"
        value={phoneData.phoneNumber}
        maxLength="10" // Limit the maximum length to 10 digits
        onChange={handlePhoneChange}
      />
    </div>
              <div className="flex flex-col">
                <label htmlFor="companyName" className="font-medium text-sm text-black">Company name</label>
                <input
                  type="text"
                  id="companyName"
                  name="companyName"
                  className="border rounded-md p-2 mt-1 focus:outline-none w-full"
                  value={formData.companyName}
                  onChange={handleChange}
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="country" className="font-medium text-sm text-black mb-2">Country</label>
                <Select
                  id="country"
                  name="country"
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  options={countryOptions}
                  className="basic-single"
                  classNamePrefix="select"
                />
              </div>
            </div>
            <div className="flex flex-col mt-2">
              <label htmlFor="message" className="font-medium text-sm text-black">What you need?</label>
              <textarea
                id="message"
                name="message"
                rows="3"
                className="border rounded-md p-2 mt-1 focus:outline-none w-full resize-none"
                value={formData.message}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="flex justify-start mt-4">
              <button
                type="submit"
                className="bg-[#034485] text-white font-medium text-sm tracking-wide leading-loose py-2 px-4 focus:outline-none"
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Frame7;

import React from 'react';
import { Link } from 'react-scroll';

function Frame8() {
  return (
    <div className="bg-[#464F60] flex flex-col items-center p-4 lg:p-[151px_58.2px_22px_77px] w-full lg:w-[full] box-border">
      <div className="flex flex-col lg:flex-row w-full lg:w-[fit-content] m-4 lg:m-[0_0_18px_17px] box-border">
        <div className="flex flex-col mb-8 lg:mb-0  lg:mr-[143.3px] box-border">
          <div className=" mx-4 text-start text-[24px] lg:text-[29px] font-['Plus_Jakarta_Sans']  text-white tracking-wide">
            Elon Native System
          </div>
          <span className="mx-4 text-start text-[14px] lg:text-[17px] font-['Plus_Jakarta_Sans']  font-normal text-white leading-relaxed tracking-wide mt-4">
            At Elon Native Systems, we lead the way in using IT technology <br className="hidden lg:inline" />
            to revolutionize healthcare. With our deep expertise in both healthcare <br className="hidden lg:inline" />
            and technology, we empower providers and elevate patient care.
          </span>
        </div>
        <div className='flex flex justify-around'>
        <div className="flex flex-col  items-center lg:mb-12 lg:mr-[103.4px] box-border">
          <div className="text-[20px] lg:text-[24px] font-['Plus_Jakarta_Sans']  text-white tracking-wide ">
            Company
          </div>
          <div className="flex flex-col text-start mt-9  sm:mb-[11] text-[14px] lg:text-[17px] font-['Plus_Jakarta_Sans']  font-normal text-white leading-relaxed tracking-wide">
            <Link
              to="home"
              spy={true}
              smooth={true}
              duration={500}
              className="mb-3 cursor-pointer"
              activeClass="active"
            >
              Home
            </Link>
            <Link
              to="about-us"
              spy={true}
              smooth={true}
              duration={500}
              className="mb-3 cursor-pointer"
              activeClass="active"
            >
              About us
            </Link>
            <Link
              to="services"
              spy={true}
              smooth={true}
              duration={500}
              className="mb-3 cursor-pointer"
              activeClass="active"
            >
              Services
            </Link>
            <Link
              to="our-team"
              spy={true}
              smooth={true}
              duration={500}
              className="mb-3 cursor-pointer"
              activeClass="active"
            >
              Our Team
            </Link>
            <Link
              to="tech-stacks"
              spy={true}
              smooth={true}
              duration={500}
              className="mb-3 cursor-pointer"
              activeClass="active"
            >
              Tech Stacks
            </Link>
            <Link
              to="demo"
              spy={true}
              smooth={true}
              duration={500}
              className="mb-3 cursor-pointer"
              activeClass="active"
            >
              Demo
            </Link>
            <Link
              to="contact"
              spy={true}
              smooth={true}
              duration={500}
              className="cursor-pointer mb-3 "
              activeClass="active"
            >
              Contact us
            </Link>
            <Link
              to="career"
              spy={true}
              smooth={true}
              duration={500}
              className="cursor-pointer mb-3 "
              activeClass="active"
            >
              Career
            </Link>
          </div>
        </div>
        <div className="flex flex-col box-border">
          <div className=" text-start text-[20px] lg:text-[24px] font-['Plus_Jakarta_Sans']  text-white tracking-wide mb-6">
            Contact Info
          </div>
          <div className="text-[14px] lg:text-[17px] font-['Plus_Jakarta_Sans']  font-normal text-white leading-relaxed tracking-wide">
            <div className="mb-6">
              <div className="font-extrabold  text-start">Address</div>
              <span className="block mt-1  text-start">
                Near Manthai,<br />
                Mamsapuram,<br />
                Srivilliputhur - 626110
              </span>
            </div>
            <div className="mb-6 text-start">
  <div className="font-['Plus_Jakarta_Sans']">Phone</div>
  <a href="tel:+917339055489" className="block mt-1 text-white">
    +91-7339055489
  </a>
</div>

<div className="flex flex-col text-start text-white">
  <div className="text-[14px] lg:text-[17px] font-['Plus_Jakarta_Sans'] font-normal leading-relaxed tracking-wide mb-6">
    <div className="font-bold text-base lg:text-lg">Email</div>
    <span className="block mt-1 text-[14px] lg:text-[17px]">
      admin@elonnativesystem.com
    </span>
  </div>
</div>

          </div>
        </div>
        </div>
      </div>
      <div className="bg-white w-full lg:w-[1262px] h-[1px] mb-8 lg:mb-[35px]"></div>
      <span className="text-[14px] lg:text-[18px] font-['Plus_Jakarta_Sans']  font-normal text-white tracking-wide">
        ELON NATIVE SYSTEM @ 2021
      </span>
    </div>
  );
}

export default Frame8;

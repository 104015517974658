import React from 'react';
import nextJsImg from './assests/nextjs.png';
import Cerner from './assests/Cerner2.png';
import Athena from './assests/athena 2.png';
import Epic from './assests/epic 2.png';
import Medi from './assests/medi2 2.png';
import Vista from './assests/vista2.png';
import Netsmart from './assests/Netsmart2.png';
import Drfirst from './assests/Drfirst2.png';
import Philips from './assests/Philips2.png';
import Dicom from './assests/Dicom2.png';
import Smile from './assests/SmileCDR 2.png';
import javaImg from './assests/java.png';
import nodeJsImg from './assests/javascript.png';
import vueJsImg from './assests/vue.png';
import mirthImg from './assests/mirth.png';
import reactJsImg from './assests/react.png';
import netImg from './assests/net.png';
import cacheImg from './assests/cache.png';
import figmaImg from './assests/figma.png';
import Miro from './assests/miro2.png';
import pythonImg from './assests/python.png';
import mongoImg from './assests/mongo.png';
import springImg from './assests/spring.png';
import Adope from './assests/adope2.png';
import nativeImg from './assests/react.png';
import flutterImg from './assests/flutter.png';
import sqlImg from './assests/sql.png';
import angularImg from './assests/angular.png';
import interImg from './assests/intersystem.png';
import Chache from './assests/cache_logo.png';
import awsImg from './assests/aws.png';
import AspNet from './assests/asp.net.png';
import ChatGPT from './assests/chatgpt.png';
import Aidoc from './assests/aidoc.png';
import Watson from './assests/ibmwatson.png';
import UiPath from './assests/uipath.png';
import mBERT from './assests/mbert.png';
import Bouy from './assests/Buoy.jpg';
import Tembus from './assests/tembus.jpg';
import Micro from './assests/bot.png';
import BioBERT from './assests/naver.png';
import ChemBERT from './assests/AstraZeneca.png';
import Naunce from './assests/NuanceLogo.png';
import Medpalm from './assests/palm.png';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './Spamie.css'; // Assuming you have your CSS imports here
import Demo from '../src/Demo';
const responsiveSettings = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1024 },
    items: 8
  },
  desktop: {
    breakpoint: { max: 1024, min: 768 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};


function Frame6() {
  return (

    <div className=" w-full bg-[#F5FFFC] py-16 ">
      <div className="App ">
        <header className="App-header w-full  ">
          <h1 className=' text-[20px] font-bold font-[Poppins] '>Integrating EMR/EHR</h1>
        </header>
        <div className="container mx-auto">

          <div className="steps-container grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 justify-center lg:justify-center">

            <div className="step border border-green-400 shadow-xl p-4">
              <h3 className='font-bold text-center mb-6 font-[Poppins]'>01 Product Understanding</h3>
              <p className='font-[Poppins]'>We understand the technical architecture or components of your healthcare product.</p>
              <div className="arrow"></div>
            </div>

            <div className="step border border-green-400 shadow-xl p-4">
              <h3 className='font-bold text-center mb-6 font-[Poppins]'>02 Team Allocation</h3>
              <p className='font-[Poppins]'>We allocate a dedicated team for your EHR/EMR integration project.</p>
              <div className="arrow"></div>
            </div>

            <div className="step border border-green-400 shadow-xl p-4">
              <h3 className='font-bold text-center mb-6 font-[Poppins]'>03 Integration</h3>
              <p className='font-[Poppins]'>The technical team executes integration while following all interoperability standards.</p>
              <div className="arrow"></div>
            </div>

            <div className="step border border-green-400 shadow-xl p-4">
              <h3 className='font-bold text-center mb-6 font-[Poppins]'>04 Pilot</h3>
              <p className='font-[Poppins]'>We test how your product establishes a link with EMR/EHR and share data in real-time.</p>
            </div>

          </div>
        </div>
      </div>
      <Carousel
        swipeable
        draggable={false}

        responsive={responsiveSettings}
        infinite
        autoPlay
        autoPlaySpeed={1000}
        keyBoardControl
        customTransition="all .5"
        transitionDuration={100}
        containerClass="carousel-container"
        removeArrowOnDeviceType={['tablet', 'mobile', ' superLargeDesktop', 'desktop']}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        className=" flex overflow-hidden shadow-md bg-gray-200  z-10"
      >
        {[Cerner, Athena, Epic, Medi, Vista, Netsmart, Drfirst, Dicom, Philips, Smile, Cerner, Athena, Epic, Medi, Vista, Netsmart, Drfirst, Dicom, Philips, Smile].map((src, index) => (
          <div key={index} className="m-4 flex flex-col lg:p-0  mt-0   md:h-30 lg:h-30  xl:h-30 items-center lg:flex-row w-[full]">
            <div className="mx-auto flex lg:gap-10 md:gap-10 w-full mt-10">
              <div className="text-center">
                <img className="mx-auto h-36 md:h-20 lg:h-30 p-2 rounded" src={src} alt={`tech-${index}`} />
              </div>
            </div>
          </div>
        ))}

      </Carousel>

      <div id="demo">
      <Demo />
      </div>


      {/* Tech Stacks */}
      <header className="App-header  font-['Poppins'] mt-5">
        <h1 className='text-[20px] font-bold '>Tech Stacks</h1>
      </header>
      <div className="mt-5">
        {/* UI UX  */}
        <div className="">
          <div className="flex justify-center bg-gradient-to-l from-cyan-600 p-2">
            <h1 className="font-[Poppins] font-bold">UI UX Design</h1>
          </div>
          <div className="flex justify-center mt-10  gap-4 flex-wrap">
            {/* Figma */}
            <div className="border w-32 h-24 rounded-lg overflow-hidden bg-white shadow-md">
              <div className="text-center mt-4">
                <img className="mx-auto h-8 lg:h-12" src={figmaImg} alt="Figma" />
              </div>
              <div className="text-center">
                <p className="text-md font-medium text-gray-700">Figma</p>
              </div>
            </div>
            {/* Adope Xd */}
            <div className="border w-32 h-24 rounded-lg overflow-hidden bg-white shadow-md">
              <div className="text-center mt-4">
                <img className="mx-auto h-8 lg:h-12" src={Adope} alt="Adope Xd" />
              </div>
              <div className="text-center">
                <p className="text-md font-medium text-gray-700">Adope Xd</p>
              </div>
            </div>
            {/* miro */}
            <div className="border w-32 h-24 rounded-lg overflow-hidden bg-white shadow-md">
              <div className="text-center mt-4">
                <img className="mx-auto h-8 lg:h-12" src={Miro} alt="Miro" />
              </div>
              <div className="text-center">
                <p className="text-md font-medium text-gray-700">Miro</p>
              </div>
            </div>
          </div>
        </div>

        {/* Frontend */}
        <div className="mt-5">
          <div className="flex justify-center bg-gradient-to-r from-cyan-600 p-2">
            <h1 className="font-[Poppins] font-bold">Frontend</h1>
          </div>
          <div className="flex justify-center mt-5">
            <h1 className="font-[Poppins] font-bold">Web</h1>
          </div>
          <div className="flex justify-center mt-10 mb-5 gap-4 flex-wrap">
            {/* Next JS */}
            <div className="border w-32 h-24 rounded-lg overflow-hidden bg-white shadow-md">
              <div className="text-center mt-4">
                <img className="mx-auto h-8 lg:h-12" src={nextJsImg} alt="Next JS" />
              </div>
              <div className="text-center">
                <p className="text-md font-medium text-gray-700">Next JS</p>
              </div>
            </div>
            {/* Angular */}
            <div className="border w-32 h-24 rounded-lg overflow-hidden bg-white shadow-md">
              <div className="text-center mt-4">
                <img className="mx-auto h-8 lg:h-12" src={angularImg} alt="Angular" />
              </div>
              <div className="text-center">
                <p className="text-md font-medium text-gray-700">Angular</p>
              </div>
            </div>
            {/* React JS */}
            <div className="border w-32 h-24 rounded-lg overflow-hidden bg-white shadow-md">
              <div className="text-center mt-4">
                <img className="mx-auto h-8 lg:h-12" src={reactJsImg} alt="React JS" />
              </div>
              <div className="text-center">
                <p className="text-md font-medium text-gray-700">React JS</p>
              </div>
            </div>
            {/* Vue JS */}
            <div className="border w-32 h-24 rounded-lg overflow-hidden bg-white shadow-md">
              <div className="text-center mt-4">
                <img className="mx-auto h-8 lg:h-12" src={vueJsImg} alt="Vue JS" />
              </div>
              <div className="text-center">
                <p className="text-md font-medium text-gray-700">Vue JS</p>
              </div>
            </div>
            {/* Asp.Net */}
            <div className="border w-32 h-24 rounded-lg overflow-hidden bg-white shadow-md">
              <div className="text-center mt-4">
                <img className="mx-auto h-8 lg:h-12" src={AspNet} alt="Asp.Net" />
              </div>
              <div className="text-center">
                <p className="text-md font-medium text-gray-700">Asp.Net</p>
              </div>
            </div>
            {/* .NET */}
            <div className="border w-32 h-24 rounded-lg overflow-hidden bg-white shadow-md">
              <div className="text-center mt-4">
                <img className="mx-auto h-8 lg:h-12" src={netImg} alt=".NET" />
              </div>
              <div className="text-center">
                <p className="text-md font-medium text-gray-700">.NET</p>
              </div>
            </div>
          </div>

          <div className="flex justify-center mt-5">
            <h1 className="font-[Poppins] font-bold">Mobile</h1>
          </div>
          <div className="flex justify-center mt-5 gap-4 flex-wrap">
            {/* Flutter */}
            <div className="border w-32 h-24 rounded-lg overflow-hidden bg-white shadow-md">
              <div className="text-center mt-4">
                <img className="mx-auto h-8 lg:h-12" src={flutterImg} alt="Flutter" />
              </div>
              <div className="text-center">
                <p className="text-md font-medium text-gray-700">Flutter</p>
              </div>
            </div>
            {/* React Native */}
            <div className="border w-32 h-24 rounded-lg overflow-hidden bg-white shadow-md">
              <div className="text-center mt-4">
                <img className="mx-auto h-8 lg:h-12" src={nativeImg} alt="React Native" />
              </div>
              <div className="text-center">
                <p className="text-md font-medium text-gray-700">React Native</p>
              </div>
            </div>
          </div>
        </div>

        {/* Backend */}
        <div className="mt-5">
          <div className="flex justify-center bg-gradient-to-l from-cyan-600 p-2 ">
            <h1 className="font-[Poppins] font-bold">Backend</h1>
          </div>
          <div className="flex justify-center mt-10  gap-4 flex-wrap ">
            {/* Java */}
            <div className="border w-32 h-24 rounded-lg overflow-hidden bg-white shadow-md">
              <div className="text-center mt-4">
                <img className="mx-auto h-8 lg:h-12" src={javaImg} alt="Java" />
              </div>
              <div className="text-center">
                <p className="text-md font-medium text-gray-700">Java</p>
              </div>
            </div>
            {/* Node JS */}
            <div className="border w-32 h-24 rounded-lg overflow-hidden bg-white shadow-md">
              <div className="text-center mt-4">
                <img className="mx-auto h-8 lg:h-12" src={nodeJsImg} alt="Node JS" />
              </div>
              <div className="text-center">
                <p className="text-md font-medium text-gray-700">Node JS</p>
              </div>
            </div>
            {/* Python */}
            <div className="border w-32 h-24 rounded-lg overflow-hidden bg-white shadow-md">
              <div className="text-center mt-4">
                <img className="mx-auto h-8 lg:h-12" src={pythonImg} alt="Python" />
              </div>
              <div className="text-center">
                <p className="text-md font-medium text-gray-700">Python</p>
              </div>
            </div>
            {/* Spring Boot */}
            <div className="border w-32 h-24 rounded-lg overflow-hidden bg-white shadow-md">
              <div className="text-center mt-4">
                <img className="mx-auto h-8 lg:h-12" src={springImg} alt="Spring Boot" />
              </div>
              <div className="text-center">
                <p className="text-md font-medium text-gray-700">Spring Boot</p>
              </div>
            </div>
            {/* Intersystem Cache */}
            <div className="border w-32 h-24 rounded-lg overflow-hidden bg-white shadow-md">
              <div className="text-center mt-4">
                <img className="mx-auto h-8 lg:h-12" src={cacheImg} alt="Intersystem Cache" />
              </div>
              <div className="text-center">
                <p className="text-md font-medium text-gray-700">Intersystems Cache</p>
              </div>
            </div>
            {/* Mirth Connect */}
            <div className="border w-32 h-24 rounded-lg overflow-hidden bg-white shadow-md">
              <div className="text-center mt-4">
                <img className="mx-auto h-8 lg:h-12" src={mirthImg} alt="Mirth Connect" />
              </div>
              <div className="text-center">
                <p className="text-md font-medium text-gray-700">Mirth Connect</p>
              </div>
            </div>
            {/* InterSystems Ensemble */}
            <div className="border w-32 h-24 rounded-lg overflow-hidden bg-white shadow-md">
              <div className="text-center mt-4">
                <img className="mx-auto h-8 lg:h-12" src={interImg} alt="InterSystems Ensemble" />
              </div>
              <div className="text-center">
                <p className="text-md font-medium text-gray-700">InterSystems Healthshare</p>
              </div>
            </div>
          </div>
        </div>

        {/* Database */}
        <div className="mt-5">
          <div className="flex justify-center bg-gradient-to-r from-cyan-600 p-2">
            <h1 className="font-[Poppins] font-bold">Database</h1>
          </div>
          <div className="flex justify-center mt-10  gap-4 flex-wrap">
            {/* SQL */}
            <div className="border w-32 h-24 rounded-lg overflow-hidden bg-white shadow-md">
              <div className="text-center mt-4">
                <img className="mx-auto h-8 lg:h-12" src={sqlImg} alt="SQL" />
              </div>
              <div className="text-center">
                <p className="text-md font-medium text-gray-700">SQL</p>
              </div>
            </div>
            {/* MongoDB */}
            <div className="border w-32 h-24 rounded-lg overflow-hidden bg-white shadow-md">
              <div className="text-center mt-4">
                <img className="mx-auto h-8 lg:h-12" src={mongoImg} alt="MongoDB" />
              </div>
              <div className="text-center">
                <p className="text-md font-medium text-gray-700">MongoDB</p>
              </div>
            </div>
            {/* Cache */}
            <div className="border w-32 h-24 rounded-lg overflow-hidden bg-white shadow-md">
              <div className="text-center mt-4">
                <img className="mx-auto h-8 lg:h-12" src={Chache} alt="MongoDB" />
              </div>
              <div className="text-center">
                <p className="text-md font-medium text-gray-700">InterSystems Cache</p>
              </div>
            </div>
          </div>
        </div>


        {/* AI */}
        <div className="mt-5 ">
          <div className="flex justify-center bg-gradient-to-l from-cyan-600 p-2">
            <h1 className="font-[Poppins] font-bold">AI Tools</h1>
          </div>
          <div className="flex justify-center mt-10 mb-5 gap-4 flex-wrap">
            {/* ChatGPT */}
            <div className="border w-32 h-24 rounded-lg overflow-hidden bg-white shadow-md">
              <div className="text-center mt-4">
                <img className="mx-auto h-8 lg:h-12" src={ChatGPT} alt="ChatGPT" />
              </div>
              <div className="text-center">
                <p className="text-md font-medium text-gray-700">ChatGPT</p>
              </div>
            </div>
            <div className="flex   gap-4 flex-wrap">
              {/* AWS */}
              <div className="border w-32 h-24 rounded-lg overflow-hidden bg-white shadow-md">
                <div className="text-center mt-4">
                  <img className="mx-auto h-8 lg:h-12" src={awsImg} alt="AWS" />
                </div>
                <div className="text-center">
                  <p className="text-md font-medium text-gray-700">AWS</p>
                </div>
              </div>
            </div>
            {/* Nuance */}
            <div className="border w-32 h-24 rounded-lg overflow-hidden bg-white shadow-md">
              <div className="text-center mt-4">
                <img className="mx-auto h-8 lg:h-12" src={Naunce} alt="Nuance" />
              </div>
              <div className="text-center">
                <p className="text-md font-medium text-gray-700">Nuance</p>
              </div>
            </div>
            {/* Bouy */}
            <div className="border w-32 h-24 rounded-lg overflow-hidden bg-white shadow-md">
              <div className="text-center mt-4">
                <img className="mx-auto h-8 lg:h-12" src={Bouy} alt="Bouy" />
              </div>
              <div className="text-center">
                <p className="text-md font-medium text-gray-700">Buoy</p>
              </div>
            </div>
            {/* UiPath */}
            <div className="border w-32 h-24 rounded-lg overflow-hidden bg-white shadow-md">
              <div className="text-center mt-4">
                <img className="mx-auto h-8 lg:h-12" src={UiPath} alt="Uipath" />
              </div>
              <div className="text-center">
                <p className="text-md font-medium text-gray-700">UiPath</p>
              </div>
            </div>
            {/* Microsoft Bo */}
            <div className="border w-32 h-24 rounded-lg overflow-hidden bg-white shadow-md">
              <div className="text-center mt-4">
                <img className="mx-auto h-8 lg:h-12" src={Micro} alt="Microsoft Bot" />
              </div>
              <div className="text-center">
                <p className="text-md font-medium text-gray-700">Microsoft Bot</p>
              </div>
            </div>
            {/* BioBE */}
            <div className="border w-32 h-24 rounded-lg overflow-hidden bg-white shadow-md">
              <div className="text-center mt-4">
                <img className="mx-auto h-8 lg:h-12" src={BioBERT} lt="BioBERT" />
              </div>
              <div className="text-center">
                <p className="text-md font-medium text-gray-700">Bio BERT</p>
              </div>
            </div>
            {/* chembert */}
            <div className="border w-32 h-24 rounded-lg overflow-hidden bg-white shadow-md">
              <div className="text-center mt-4">
                <img className="mx-auto h-8 lg:h-12" src={ChemBERT} alt="ChemBERT" />
              </div>
              <div className="text-center">
                <p className="text-md font-medium text-gray-700">ChemBERT</p>
              </div>
            </div>
            {/* mbert */}
            <div className="border w-32 h-24 rounded-lg overflow-hidden bg-white shadow-md">
              <div className="text-center mt-4">
                <img className="mx-auto h-8 lg:h-12" src={mBERT} alt="MBERT" />
              </div>
              <div className="text-center">
                <p className="text-md font-medium text-gray-700">Mbert</p>
              </div>
            </div>
            {/* Tembus */}
            <div className="border w-32 h-24 rounded-lg overflow-hidden bg-white shadow-md">
              <div className="text-center mt-4">
                <img className="mx-auto h-8 lg:h-12" src={Tembus} alt="Tembus" />
              </div>
              <div className="text-center">
                <p className="text-md font-medium text-gray-700">Tembus</p>
              </div>
            </div>
            {/* Aidoc */}
            <div className="border w-32 h-24 rounded-lg overflow-hidden bg-white shadow-md">
              <div className="text-center mt-4">
                <img className="mx-auto h-8 lg:h-12" src={Aidoc} alt="Aidoc" />
              </div>
              <div className="text-center">
                <p className="text-md font-medium text-gray-700">Aidoc</p>
              </div>
            </div>
            {/* watosn */}
            <div className="border w-32 h-24 rounded-lg overflow-hidden bg-white shadow-md">
              <div className="text-center mt-4">
                <img className="mx-auto h-8 lg:h-12" src={Watson} alt="IBM Watson" />
              </div>
              <div className="text-center">
                <p className="text-md font-medium text-gray-700">IBM Watson</p>
              </div>
            </div>
            {/* medpalm */}
            <div className="border w-32 h-24 rounded-lg overflow-hidden bg-white shadow-md">
              <div className="text-center mt-4">
                <img className="mx-auto h-8 lg:h-12" src={Medpalm} alt="MedPalm" />
              </div>
              <div className="text-center">
                <p className="text-md font-medium text-gray-700">Med Palm</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default Frame6;


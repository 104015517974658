import React, { useEffect, useState } from 'react';
import { Link, Element } from 'react-scroll';
import backgroundImage from './assests/123.png';
import vector from './assests/Vector.png';
import './Spamie.css'; // Assuming you have your CSS imports here
import Frame2 from './Frame2';
import Frame3 from './Frame3';
import Frame4 from './Frame4';
import Career from './Career';
import Frame6 from './Frame6';
import Frame7 from './Frame7';
import Frame8 from './Frame8';
import Demo from './Demo';
// import Career from './Career';
import Teams from './Teams';
import { FaLinkedin } from "react-icons/fa6";

export default function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="relative w-full overflow-x-hidden box-sizing-border">
      {/* Navbar Section */}
      <div className="bg-[#D9D9D9] fixed top-0 left-0 w-full z-50 box-sizing-border">
        <div className="bg-[#FFFFFF] flex flex-row justify-between items-center md:p-4 lg:p-6 w-full h-[2rem] box-sizing-border">
        <div className="flex items-center">
  <Link
    to="Landing"
    spy={true}
    smooth={true}
    duration={500}
    className="flex items-center cursor-pointer"
  >
    <img
      src={vector}
      alt="Logo"
      className="rotate-[360.973deg] mr-2 mx-5 w-6 h-6 md:w-8 md:h-8"
    />
    <span className="font-Poppins font-semibold text-md md:text-md lg:text-2xl tracking-[0.5px] text-[rgba(0,0,0,0.8)]">
      Elon Native System 
    </span>
  </Link>
</div>

          <div className="hidden md:flex items-center space-x-4 md:space-x-4 lg:space-x-8 ">
            <Link
              to="Landing"
              spy={true}
              smooth={true}
              duration={500}
              className="nav-link cursor-pointer sm:text-sm md:text-[13px] lg:text-[15px]"
              activeClass="active"
            >
              Home
            </Link>
            <Link
              to="about-us"
              spy={true}
              smooth={true}
              duration={500}
              className="nav-link cursor-pointer sm:text-sm lg:text-[15px] md:text-[13px]"
              activeClass="active"
            >
              About us
            </Link>
            <Link
              to="services"
              spy={true}
              smooth={true}
              duration={500}
              className="nav-link cursor-pointer sm:text-sm lg:text-[15px] md:text-[13px]"
              activeClass="active"
            >
              Services
            </Link>
            <Link
              to="Tech"
              spy={true}
              smooth={true}
              duration={500}
              className="nav-link cursor-pointer sm:text-sm lg:text-[15px] md:text-[13px]"
              activeClass="active"
            >
              Tech Stacks
            </Link>
            
            <Link
              to="demo"
              spy={true}
              smooth={true}
              duration={500}
              className="nav-link cursor-pointer sm:text-sm lg:text-[15px] md:text-[13px]"
              activeClass="active"
              
            >
               Demo
            </Link>
            <Link
              to="our-team"
              spy={true}
              smooth={true}
              duration={500}
              className="nav-link cursor-pointer sm:text-sm lg:text-[15px] md:text-[13px]"
              activeClass="active"
            >
              Our Team
            </Link>
            <Link
              to="contact"
              spy={true}
              smooth={true}
              duration={500}
              className="nav-link cursor-pointer sm:text-sm lg:text-[15px] md:text-[13px]"
              activeClass="active"
            >
              Contact
            </Link>
           
            <Link
              to="career"
              spy={true}
              smooth={true}
              duration={500}
              className="nav-link cursor-pointer sm:text-sm lg:text-[15px] md:text-[13px]"
              activeClass="active"
              
            >
               Career
            </Link>
          </div>
          <div className="flex items-center space-x-4 md:space-x-6 lg:space-x-8 ">
            <div className="relative flex items-center box-sizing-border">
              <a href='https://www.linkedin.com/company/elon-native-systems/?originalSubdomain=in' target='_new' >
            <FaLinkedin className='w-6 h-6  lg:w-10 lg:h-10 lg:rounded-xl hover:text-blue-700' />
            </a>
            </div>
            <button
              className="md:hidden outline-none focus:outline-none"
              onClick={toggleMenu}
            >
              <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
            </button>
          </div>
        </div>
        {/* Mobile Menu */}
      
{isMenuOpen && (
  <div className="bg-[#FFFFFF] md:hidden p-4 box-sizing-border text-center">
    <Link
      to="Landing"
      spy={true}
      smooth={true}
      duration={500}
      className="block py-2 nav-link cursor-pointer sm:text-sm lg:text-md w-full hover:bg-gray-200 hover:text-black"
      activeClass="active"
      onClick={toggleMenu}
    >
      Home
    </Link>
    <Link
      to="about-us"
      spy={true}
      smooth={true}
      duration={500}
      className="block py-2 nav-link cursor-pointer sm:text-sm lg:text-md w-full hover:bg-gray-200 hover:text-black"
      activeClass="active"
      onClick={toggleMenu}
    >
      About us
    </Link>
    <Link
      to="services"
      spy={true}
      smooth={true}
      duration={500}
      className="block py-2 nav-link cursor-pointer sm:text-sm lg:text-md w-full hover:bg-gray-200 hover:text-black"
      activeClass="active"
      onClick={toggleMenu}
    >
      Services
    </Link>
    <Link
      to="Tech"
      spy={true}
      smooth={true}
      duration={500}
      className="block py-2 nav-link cursor-pointer sm:text-sm lg:text-md w-full hover:bg-gray-200 hover:text-black"
      activeClass="active"
      onClick={toggleMenu}
    >
      Tech Stacks
    </Link>
    <Link
      to="demo"
      spy={true}
      smooth={true}
      duration={500}
      className="nav-link cursor-pointer sm:text-sm lg:text-[15px] md:text-[13px]"
      activeClass="active"
      onClick={toggleMenu}
      >
      Demo
      </Link>
    <Link
      to="our-team"
      spy={true}
      smooth={true}
      duration={500}
      className="block py-2 nav-link cursor-pointer sm:text-sm lg:text-md w-full hover:bg-gray-200 hover:text-black"
      activeClass="active"
      onClick={toggleMenu}
    >
      Our Team
    </Link>
    <Link
      to="contact"
      spy={true}
      smooth={true}
      duration={500}
      className="block py-2 nav-link cursor-pointer sm:text-sm lg:text-md w-full hover:bg-gray-200 hover:text-black"
      activeClass="active"
      onClick={toggleMenu}
    >
      Contact
    </Link>
    
    <Link
      to="career"
      spy={true}
      smooth={true}
      duration={500}
      className="block py-2 nav-link cursor-pointer sm:text-sm lg:text-md w-full hover:bg-gray-200 hover:text-black"
      activeClass="active"
      onClick={toggleMenu}
    >
      Career
    </Link>
  </div>
)}
      </div>
      <div
  id='Landing'
  className="relative flex flex-col justify-center items-center p-6 md:p-12 lg:p-16 box-sizing-border bg-cover bg-center bg-fixed animate-bgBlurZoom"
  style={{
    backgroundImage: `url(${backgroundImage})`,
    minHeight: '100vh',
  }}
>
  <div className="bg-[rgba(44,48,39,0.8)] absolute left-0 top-0 w-full h-full"></div>
  
  {/* Title Section */}
  <div className="relative m-8 md:m-4 lg:m-12 font-[Poppins] text-white text-2xl md:text-4xl lg:text-5xl text-start md:text-center">
    <p className="glitch" data-text="Elon Native Systems:">
      Elon Native Systems: Your Partner in
    </p>
    
    <p className="glitch" data-text="Healthcare Software Development">
      Healthcare Software Development
    </p>
    <p className="glitch" data-text="Interoperability, and AI Integration.">
      Interoperability, and AI Integration.
    </p>
  </div>

  {/* Description Section */}
  <div className="relative m-6 md:m-2 lg:m-10 text-white font-[Poppins] sm:text-sm md:text-lg lg:text-lg text-start md:text-center w-full text-explosion">
    <p className='mx-8' >
      We specialize in developing robust, scalable, and user-friendly software solutions tailored to the unique needs of the healthcare industry. Our team of expert developers works closely with you to create custom applications that enhance patient care, streamline operations, and improve overall efficiency. We ensure compliance with healthcare standards like HL7, FHIR, CCDA, and EDI, and leverage AI tools, all while maintaining strict adherence to HIPAA regulations.
    </p>
  </div>
</div>
      <br />
      {/* Sections */}
      <Element name='home'>
        
      </Element>
      <Element name="about-us">
        <Frame2 />
      </Element>
      <Element name="services">
        <Frame3 />
      </Element>
      <Element name="frame4">
        <Frame4 />
      </Element>
      <Element name="Tech">
     <Frame6>
     <Element name="demo">
       <Demo />
       </Element>
     </Frame6>
      </Element>
      <Element name="our-team">
        <Teams />
      </Element>
      <Element name="contact">
        <Frame7 />
      </Element>
      <Element name="career">
        <Career />
      </Element>
      <Frame8 />
    </div>
  );
}
